<template>
  <div id="app">
    <nav class="navbar">
      <img
        src="@/assets/G20GLI_UNCCD_Horiz_Col_logo.png"
        alt="Logo"
        class="navbar-logo"
      />
    </nav>
    <h1>GLI JSON Editor</h1>
    <div class="file-upload-container">
      <input
        type="file"
        accept=".json"
        @change="handleFileUpload"
        class="file-input"
        ref="fileInput"
      />
      <button @click="openFileDialog" class="choose-file-button">
        Choose file
      </button>
      <span>{{ fileName }}</span>
    </div>
    <div class="count">
      <p class="visitcount">Total Visits: {{ visitCount }}</p>
      <p class="visitcount">Unique Visits: {{ uniqueCount }}</p>
    </div>
 
    <div
      v-if="!fileLoaded"
      class="drop-zone"
      @dragover.prevent
      @drop.prevent="handleDrop"
      @click="openFileDialog"
    >
      <div class="drop-zone-content">
        <img
          src="@/assets/upload-icon.svg"
          alt="Upload Icon"
          class="upload-icon"
        />
        <p>Click to upload or drag and drop</p>
        <p>No Files Selected</p>
      </div>
    </div>
    <editable-table v-else :data="data" ref="editableTable"></editable-table>
    <footer class="footer">
      <hr />
      <p>
        For comments and system modification requests, kindly reach out to the
        Information Management Team <b>Abd Salam El Vilaly</b> (<a
          href="mailto:avilaly@unccd.int"
          >avilaly@unccd.int</a
        >)
      </p>
    </footer>
  </div>
</template>

<script>
import EditableTable from "./components/EditableTable.vue";

export default {
  name: "App",
  components: {
    EditableTable,
  },
  data() {
    return {
      data: [],
      fileLoaded: false,
      fileName: "No file chosen",
      visitCount: 0,
      uniqueCount: 0,
    };
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.fileName = file.name;
      this.loadFile(file);
    },
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      this.fileName = file.name;
      this.loadFile(file);
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
    async loadFile(file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const jsonData = JSON.parse(e.target.result);
          if (Array.isArray(jsonData)) {
            if (this.data.length) {
              const saveChanges = await this.promptSaveChanges();
              if (saveChanges) {
                this.$refs.editableTable.saveJson();
              }
            }
            this.data = jsonData;
            this.fileLoaded = true;
          } else {
            alert("Invalid JSON format. Expected an array.");
          }
        } catch (error) {
          alert("Error parsing JSON file.");
        }
      };
      reader.readAsText(file);
    },
    promptSaveChanges() {
      return new Promise((resolve) => {
        const shouldSave = confirm("Do you want to save the current changes before loading new data?");
        resolve(shouldSave);
      });
    },
   async updateVisitCount() {
    try {
      const response = await fetch('/api/visits'); 
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.count !== null) {
        this.visitCount = data.totalCount;
        console.log('Total visits:', data.totalCount);
      } else {
        console.error('Received null count:', data);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  },

  async trackUniqueVisit() {
  try {
    const response = await fetch('/api/unique-visit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    if (data.uniqueCount !== null) {
      this.uniqueCount = data.uniqueCount;
    } else {
      console.error('Received null unique count:', data);
    }
  } catch (error) {
    console.error('Fetch error:', error);
  }
  },
  },
  mounted() {
    this.updateVisitCount();
    this.trackUniqueVisit();
  },
};
</script>

<style>
.count{
  display: flex;
  align-items: center;
  justify-content: center;
}
.visitcount{
  margin: 0 10px;
  font-size: 16px;
  font-weight: bold;
}
#app {
  text-align: center;
  padding: 20px;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

h1 {
  margin-bottom: 20px;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  padding: 10px;
  margin-bottom: 20px;
}

.navbar-logo {
  max-height: 60px;
}

.file-upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.choose-file-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.choose-file-button:hover {
  opacity: 0.8;
}

.drop-zone {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px dashed #ccc;
  border-radius: 10px;
  padding: 50px;
  margin: 20px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  flex-grow: 1;
}

.file-input {
  display: none;
}

.drop-zone-content {
  text-align: center;
}

.upload-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.footer {
  text-align: center;
  padding: 10px;
  background-color: #f8f9fa;
}

.footer hr {
  margin: 10px 0;
}

.footer p {
  margin: 0;
}
</style>


