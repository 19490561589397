<template>
  <div class="editor-container">
    <div class="buttons-container">
      <button class="save-button" @click="saveJson">Save</button>
      <button class="add-button" @click="addRow">Add Row</button>
      <!-- <button class="deploy-button" disabled>Deploy to Github</button> -->
    </div>
    <div class="table-container">
      <table class="json-table">
        <thead>
          <tr>
            <th>#</th>
            <th v-for="(key, index) in columns" :key="index">{{ key }}</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, rowIndex) in jsonData" :key="rowIndex">
            <td>{{ rowIndex + 1 }}</td>
            <td v-for="(key, colIndex) in columns" :key="colIndex">
              <template v-if="typeof row[key] !== 'object'">
                <input v-model="jsonData[rowIndex][key]" />
              </template>
              <template v-else>
                <button
                  class="nested-data-button"
                  @click="openNestedData(row[key], rowIndex)"
                >
                  Edit Nested Data
                </button>
              </template>
            </td>
            <td class="actions-cell">
              <div class="action-buttons">
                <button class="delete-button" @click="deleteRow(rowIndex)">
                  Delete
                </button>
                <button
                  class="duplicate-button"
                  @click="duplicateRow(rowIndex)"
                >
                  Duplicate
                </button>
                <button
                  class="insert-above-button"
                  @click="insertRowAbove(rowIndex)"
                >
                  Insert Above
                </button>
                <button
                  class="insert-below-button"
                  @click="insertRowBelow(rowIndex)"
                >
                  Insert Below
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <custom-modal v-if="showModal" @close="closeModal">
      <template v-slot:header>
        <h2>Nested Data (Row {{ currentRowIndex + 1 }})</h2>
      </template>
      <template v-slot:body>
        <div class="nested-table-container">
          <table class="json-table">
            <thead>
              <tr>
                <th>#</th>
                <th v-for="(key, index) in nestedColumns" :key="index">
                  {{ key }}
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, rowIndex) in nestedData" :key="rowIndex">
                <td>{{ rowIndex + 1 }}</td>
                <td v-for="(key, colIndex) in nestedColumns" :key="colIndex">
                  <input v-model="nestedData[rowIndex][key]" />
                </td>
                <td class="actions-cell">
                  <div class="action-buttons">
                    <button
                      class="delete-button"
                      @click="deleteNestedRow(rowIndex)"
                    >
                      Delete
                    </button>
                    <button
                      class="duplicate-button"
                      @click="duplicateNestedRow(rowIndex)"
                    >
                      Duplicate
                    </button>
                    <button
                      class="insert-above-button"
                      @click="insertNestedRowAbove(rowIndex)"
                    >
                      Insert Above
                    </button>
                    <button
                      class="insert-below-button"
                      @click="insertNestedRowBelow(rowIndex)"
                    >
                      Insert Below
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template v-slot:footer>
        <button
          class="save-button"
          @click="saveNestedData"
          style="margin-right: 10px; margin-top: 20px"
        >
          Save Nested Data
        </button>
        <button class="close-button" @click="closeModal">Close</button>
      </template>
    </custom-modal>
  </div>
</template>

<script>
import CustomModal from "./CustomModal.vue";
import FileSaver from "file-saver";
import moment from "moment";

export default {
  components: {
    CustomModal,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      jsonData: this.data,
      showModal: false,
      nestedData: [],
      nestedColumns: [],
      currentRowIndex: -1,
    };
  },
  computed: {
    columns() {
      if (!this.jsonData.length) return [];
      return Object.keys(this.jsonData[0]);
    },
  },
  methods: {
    saveJson() {
      const blob = new Blob([JSON.stringify(this.jsonData, null, 2)], {
        type: "application/json",
      });
      FileSaver.saveAs(
        blob,
        `edited-data-${moment().format("YYYYMMDDHHmmss")}.json`
      );
    },
    addRow() {
      const newRow = {};
      this.columns.forEach((column) => {
        newRow[column] = "";
      });
      this.jsonData.push(newRow);
    },
    deleteRow(index) {
      this.jsonData.splice(index, 1);
    },
    duplicateRow(index) {
      const newRow = JSON.parse(JSON.stringify(this.jsonData[index]));
      this.jsonData.splice(index + 1, 0, newRow);
    },
    insertRowAbove(index) {
      const newRow = {};
      this.columns.forEach((column) => {
        newRow[column] = "";
      });
      this.jsonData.splice(index, 0, newRow);
    },
    insertRowBelow(index) {
      const newRow = {};
      this.columns.forEach((column) => {
        newRow[column] = "";
      });
      this.jsonData.splice(index + 1, 0, newRow);
    },
    openNestedData(data, rowIndex) {
      this.nestedData = JSON.parse(JSON.stringify(data));
      this.nestedColumns = Object.keys(data[0] || {});
      this.currentRowIndex = rowIndex;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    saveNestedData() {
      this.$set(
        this.jsonData[this.currentRowIndex],
        "metadata",
        this.nestedData
      );
      this.showModal = false;
    },
    deleteNestedRow(index) {
      this.nestedData.splice(index, 1);
    },
    duplicateNestedRow(index) {
      const newRow = JSON.parse(JSON.stringify(this.nestedData[index]));
      this.nestedData.splice(index + 1, 0, newRow);
    },
    insertNestedRowAbove(index) {
      const newRow = {};
      this.nestedColumns.forEach((column) => {
        newRow[column] = "";
      });
      this.nestedData.splice(index, 0, newRow);
    },
    insertNestedRowBelow(index) {
      const newRow = {};
      this.nestedColumns.forEach((column) => {
        newRow[column] = "";
      });
      this.nestedData.splice(index + 1, 0, newRow);
    },
    updateTableData(newData) {
      this.jsonData = newData;
    },
  },
  watch: {
    data(newData) {
      this.updateTableData(newData);
    },
  },
};
</script>

<style>
.editor-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  flex-grow: 1;
}

.buttons-container {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
  width: 100%;
}

.save-button {
  background-color: #28a745;
  color: white;
}

.add-button {
  background-color: #007bff;
  color: white;
}

.deploy-button {
  background-color: #ffc107;
  color: black;
}

.table-container {
  width: 100%;
  max-width: 1200px;
  overflow-x: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background-color: #fff;
  max-height: 60vh;
  overflow-y: auto;
}

.json-table {
  width: 100%;
  border-collapse: collapse;
}

.json-table th,
.json-table td {
  border: 1px solid #ccc;
  padding: 8px;
}

.json-table th {
  background-color: #f4f4f4;
}

.actions-cell {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
}

.action-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
}

button,
.nested-data-button {
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  color: white;
}

button:hover,
.nested-data-button:hover {
  opacity: 0.8;
}

.nested-data-button {
  background-color: #17a2b8;
}

.delete-button {
  background-color: #dc3545;
}

.duplicate-button {
  background-color: #ffc107;
  color: black;
}

.insert-above-button,
.insert-below-button {
  background-color: #6c757d;
}

.custom-modal .json-table {
  margin-bottom: 20px;
}

.save-button {
  background-color: #28a745;
  color: white;
}

.close-button {
  background-color: #007bff;
  color: white;
}
</style>
